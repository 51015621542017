export default {
    name: 'treeTable',
    data () {
        return {}
    },
    props: {
        list: {
            type: Object,
            default: () => {}
        },
        isLast: { // 是否是这层的最后一个
            type: Boolean,
            default: false
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        add(val) {
            this.$emit('add', val)
        },
        edit(val) {
            this.$emit('edit', val)
        },
        change(val) {
            this.$emit('change', val)
        },
        del(val) {
            this.$emit('del', val)
        },
        handlerExpand(val) {
            this.$emit('handlerExpand', val)
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/